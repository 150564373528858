.login_navbar {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 16px;
  /* backdrop-filter: blur(7px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.2);
  background-color: color-mix(in srgb, #fff 40%, transparent); */

  z-index: 10;

  .login_navbar_inner_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1280px;

    @media screen and (width <=700px) {
      /* justify-content: flex-end; */
    }

    .lura_care_logo {
      height: 60px;
      min-width: 100px;
      width: fit-content;

      @media screen and (width <=500px) {
        min-width: 30px;

        .logo_main_text,
        .logo_footer_text {
          display: none;
        }
      }

      @media screen and (width <=768px) {
        visibility: hidden;
      }
    }
  }
}