.logo-div {
  position: absolute;
  left: 0;
  margin: 24px;
  width: 12%;
}

.desktop-input-max-width {
  max-width: 510px;
}

.mobile-input-max-width {
  max-width: 266.5px;
}

.logo-div-responsive {
  width: 46%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 7%;
  margin-top: 43px;
}

.form-title {
  text-align: center;
  color: #28337d !important;
  font-size: 38px;
  margin-bottom: 34px;
}

.form-title-forgot-password {
  margin-top: 75px;
  text-align: center;
  color: #28337d !important;
  font-size: 38px;
  margin-bottom: 34px;
}

.form-title-forgot-password-small {
  text-align: center;
  color: #28337d !important;
  font-size: 28px;
  margin-bottom: 28px;
}

.help-screen-height-mobile {
  height: calc(100vh - 146px);
  overflow-y: scroll;
}

.form-title-small {
  text-align: center;
  color: #28337d !important;
  font-size: 28px;
  margin-bottom: 28px;
}

.left-image {
  background-color: #f5e1e54d;
}

.left-image img {
  margin: auto;
  width: 90%;
  height: auto;
}

.purple-text {
  color: #b2637f !important;
  font-size: 18px;
  font-weight: 400;
}

.form-div {
  width: 100%;
}

.form-div-small {
  width: 265.5px;
}

.form-div-forgot-password-small {
  margin: 45px 15px 0;
  width: 100%;
}

.form-container-forgot-password {
  margin: 1rem;
}

.form-explanation {
  white-space: pre-line;
  white-space: -moz-pre-line;
  white-space: -o-pre-line;
}

.recover-password-message {
  font-size: 16px;
  width: 102%;
  margin-top: 20px;
  margin-bottom: 24px;
}

.forgot-password-container-form-type-selector {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 15px;
}

.forgot-password-form-type-selector {
  font-size: 20px;
  font-weight: 700;
  color: #aaaaaa;
}

.forgot-password-form-type-selector-selected {
  font-size: 20px;
  font-weight: 900;
  color: #b2637f;
  border-bottom: 1px solid black;
}

.forgot-password-form-type-selector:hover {
  cursor: pointer;
}

.main-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
}

.main-container-forgot-password {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100vh;
}

.main-container-forgot-password-responsive {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.error-message {
  font-size: 10px;
  color: red;
}