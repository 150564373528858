.main-container {
  width: 100%;
  min-width: 280px;
  height: 100%;
  padding: 0px;

  @media screen and (width >=769px) {
    padding: 20px;
  }

  .back-arrow-container {
    position: relative;
    display: none;
    flex-direction: row;
    text-align: left;
    gap: 16px;

    @media screen and (width >=769px) {
      display: flex;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 100px;
    padding-bottom: 120px;
    text-align: left;

    @media screen and (width >=769px) {
      margin-top: 0px;
      padding-bottom: 40px;
    }

    .summary-container {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: min(600px, 100%);
      margin-inline: auto;

      .pago-resumen-container {
        width: 100%;
        border: 1px solid #d6d6e5;
        border-radius: 12px;
        background-color: #fff;
        height: fit-content;
        padding: 16px;
      }

      .quote-accepted-payment-summary {
        color: gray;
      }

      .quote-accepted-text-check {
        display: flex;
        flex-direction: row;
        gap: 5px;
        align-items: center;
      }

      .quote-accepted-green-check {
        width: 15px;
        height: 15px;
      }
    }

    .buttons-container {
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      gap: 16px;
      width: 100%;

      @media screen and (width >=450px) {
        flex-direction: row;
      }
    }
  }
}